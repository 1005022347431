
:root {
    --white: rgb(255, 255, 255);
    --black: rgba(42, 43, 50, 1);
    --blackCenterBoxes: rgba(42, 43, 50, 0.9);
    --blackAbouteMeBG: rgba(42, 43, 50, 0.6);
    --blackBg: rgba(42, 43, 50, 0.3);
    --blackBgSm: rgba(42, 43, 50, 0.5);
}

body {
    background-color: var(--black);
    font-size: 100%;
}

.backgroundImg {
    height: 100vh;
    width: 100vw;
    z-index: -1;
    position: absolute;
}


/*


    HOME 


*/

button {
    cursor: pointer;
    background-color: transparent;
    color: var(--white);
}

.flexCentered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    font-family: Roboto;
    color: var(--white);
    margin: 0;
}

.display {
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: var(--blackBg);
}

.centerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 50px;
    width: 800px;
    height: 500px;
    box-shadow: 0px 0px 29px 7px rgba(0,0,0,0.2);
    background-color: var(--blackCenterBoxes);
}

.centerBox-button {
    border: 3px solid var(--white);
    border-radius: 15px;
    font-size: 28px;
}
.centerBox-button-Home {
    width: 250px;
    height: 80px;
}
.centerBox-button-Contact {
    width: 280px;
    height: 90px;
    text-decoration: none;
}
.centerBox-button:hover {
    background-color: rgba(255, 255, 255, 0.354);    
}

.textBox-greeting-myName {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 200px;
}
.greeting {
    font-size: 2.4rem;
}
.myName {
    font-size: 5.6rem;
}

.menuButtons-Box {
    width: 355px;
    height: 40px;
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
}

.menuButtons-Box > button {
    border-radius: 10px;
    width: 100px;
    height: 28px;
    border: 2px solid var(--white);
    font-size: 1.2rem;
}

.logo {
    position: absolute;
    left: 40px;
    top: 22.5px;
    height: 45px;
    width: 45px;
    cursor: pointer;
}

.logo > img {
    height: 100%;
}

.logo:hover {
    background-color: rgba(255, 255, 255, 0.354);
}
.logoText {
    color: var(--white);
    font-size: 24px;
    margin: 2px;
    letter-spacing: 1.5px;
}


.menuScreenBox {
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
    overflow: hidden;
}

.menuScreen {
    height: 100%;
    background-color: var(--black);
    flex-direction: column;
}

.menuItems {
    font-size: 40px;
    margin: 5vh;
}
.menuItems:hover{
    color: grey;
}

.arrowIcon {
    color: white;
    position: absolute;
    top: 20px;
    left: 20px;
}
.arrowIcon:hover{
    color: grey;
}


/* Menu and Logo, Home and contact Screen small size */
  @media screen and (max-width: 900px) {
    .display {
        background-color: var(--blackBgSm);
    }

    .centerBox {
        height: 450px;
        box-shadow: none;
        background-color: transparent;
    }
    
    .centerBox-button {
        width: 225px;
        height: 70px;
        font-size: 25px;
    }
    .centerBox-button:hover {
        background-color: transparent;
    }

    /* home font */
    .greeting {
        font-size: 2.2rem;
    }
    .myName {
        font-size: 3.8rem;
    }

    /* menu */
    .menuButtons-Box {
        width: 96px;
        height: 45px;
        position: absolute;
        top: 30px;
        right: 0px;
        display: flex;
        justify-content: flex-start;
    }   
    
    /* contact */
    .socialMediaBox {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 30%;
    }
  }


  
/*


    Contact 


*/
.text-and-Icon {
    width: 28%;
    gap: 8px;
    color: var(--white);
}

.socialMediaText {
    font-size: 1.9rem;
    text-decoration: none;
}



/*


    ABOUT ME 


*/


.displayAboutMe {
    background-color: var(--blackAbouteMeBG);
    align-items: flex-end;
    justify-content: center;
}

.sliderAndArrow {
    display: flex;
    height: 88vh;
    width: 85vw;
    margin-bottom: 3vh;
}

/* box in which all items are.
position relative so that the items are absolute positioned to this box and not the whole page */
.sliderBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
}

/* style of every itemBox */
.itemBox {
    height: 25%;
    width: 50%;
    display: flex;
    position: absolute;
    transition: opacity 1s, transform 1s;
}

/* here every item box is getting styled individualy */
#prev2 {
    transform: translateY(-150%);
    user-select: none
}
#prev {
    opacity: 0.5;
}
#active {
    transform: translate(-50%, 150%) scale(2);
    opacity: 1;
}
#next {
    transform: translateY(300%);
    opacity: 0.5;
}
#next2 {
    transform: translateY(450%);
    user-select: none
}

/* in the item box is a picture and text box */
.pictureBox {
    width: 45%;
}
.picture {
    border-radius: 9%;
    height: 100%;
}

.textBox {
    width: 50%;
    flex-direction: column;
}
.aboutMeText {
    transition: font-size 1s;
    font-size: 3.3vh;
}

.arrowBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.toTheTopArrowBox {
    position: absolute;
    right: 2vw;
    bottom: 2vw;
}

@media screen and (max-width: 1100px) {
    .sliderAndArrow {
        width: 90vw;
    }
  }

  /* smallSize (tablet smartphone..) */
@media screen and (max-aspect-ratio: 6/4) {

    /* style of every itemBox */
    .itemBox {
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 2%;
    }
    #active {
        transform:  translate(0%, 150%) scale(2);
    }

    /* in the item box is a picture and text box */
    .pictureBox {
        width: 100%;
        height: 70%;
    }
    .textBox {
        height: 20%;
    }

    .arrowBox{
        justify-content: space-around;
    }

    .aboutMeText {
        font-size: 2.1vh;
    }
  }

  /* smartphone */
  @media screen and (max-aspect-ratio: 7/12) {
    .sliderAndArrow {
        width: 95vw;
    }
    .pictureBox {
        height: 62%;
    }
  }

  /* long but not wide smartphone */
  @media screen and (max-aspect-ratio: 5/11) {
      .pictureBox {
          height: 50%;
        }
        .aboutMeText {
            font-size: 1.6vh;
    }
  }
  
  /* not long but wide */
  @media screen and (min-aspect-ratio: 15/5) {
    .sliderAndArrow {
        width: 50vw;
    }
  }